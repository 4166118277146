
import * as ipxStaticRuntime$ijIFps3MN5 from '/home/vsts/work/1/s/node_modules/@nuxt/image/dist/runtime/providers/ipxStatic'

export const imageOptions = {
  "screens": {
    "xs": 320,
    "sm": 599,
    "md": 959,
    "lg": 1279,
    "xl": 1919,
    "xxl": 2559,
    "2xl": 2559
  },
  "presets": {},
  "provider": "ipxStatic",
  "domains": [
    "brave-cliff-084e1ff03.5.azurestaticapps.net",
    "allmedica.com",
    "img.youtube.com",
    "i.vimeocdn.com"
  ],
  "alias": {
    "/youtube": "https://img.youtube.com",
    "/vimeo": "https://i.vimeocdn.com"
  },
  "densities": [
    1,
    2
  ],
  "format": [
    "avif",
    "webp",
    "jpg",
    "webp"
  ],
  "quality": 90
}

imageOptions.providers = {
  ['ipxStatic']: { provider: ipxStaticRuntime$ijIFps3MN5, defaults: {} }
}
        